import React from 'react'
import styled, { css } from 'styled-components'
import Anchor from './Anchor'
import FlairText from './FlairText'
import InstagramGrid from './InstagramGrid'
import LandingPageSection from './LandingPageSection'
import MailingListSubscribe from './MailingListSubscribe'
import PagePadding from './PagePadding'
import Paragraph from './Paragraph'
import StylableOutboundLink from './StylableOutboundLink'

function InstaMailchimpSection() {
  return (
    <LandingPageSection>
      <Flex>
        <Half>
          <Heading as="h2">Follow me on Instagram</Heading>
          <Content>
            <Instagram />
            <InstagramText>
              See more on Instagram{' '}
              <Anchor
                as={StylableOutboundLink}
                href="https://instagram.com/codycalligraphy"
              >
                @codycalligraphy
              </Anchor>
            </InstagramText>
          </Content>
        </Half>
        <Half>
          <Heading as="h2">
            Stay in the <LineBreaker>Know</LineBreaker>
          </Heading>
          <Content>
            <Mailchimp />
          </Content>
        </Half>
      </Flex>
    </LandingPageSection>
  )
}

export default InstaMailchimpSection

const mobile = 625

const Flex = styled(PagePadding)`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${mobile}px) {
    flex-direction: column;
  }
`

const Instagram = styled(InstagramGrid)`
  max-width: 500px;
  margin: 1.5rem auto 0.25rem;
`

const Mailchimp = styled(MailingListSubscribe)`
  margin: 1.5rem auto 0;
`

const Half = styled.div<{ centerText?: boolean }>`
  width: calc(50% - 1rem);

  ${props =>
    props.centerText &&
    css`
      text-align: center;
    `}

  @media (max-width: ${mobile}px) {
    width: 100%;
    &:first-child {
      margin-bottom: 60px;
    }
  }
`

const Content = styled.div`
  max-width: 500px;
  margin: 0 auto;
`

const Heading = styled(FlairText)`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 48px;
`

const InstagramText = styled(Paragraph)`
  text-align: right;
  margin: 0 0 1rem 0;
`

const LineBreaker = styled.span`
  @media (min-width: ${mobile}px) and (max-width: 1078px) {
    display: block;
  }
`
